.ant-layout-header {
    position: fixed;
    z-index: 5;
    width: 100%;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.5);
}

.ant-layout-footer {
    padding: 15px;
}

#PageLayout {
    margin-top: 64px;
}

@media screen and (max-width: 991px) {
    .ant-layout-sider {
        position: fixed !important;
        overflow: inherit !important;
        box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.1);
        z-index: 5;
        height: 100%;
    }

    .ant-layout-sider .ant-layout-sider-children {
        overflow: hidden auto;
    }
}

@media screen and (max-width: 1190px) {
    .ant-table-content {
        overflow: auto;
    }
}

@media print {
    .no-print {
        display: none;
    }

    * {
        color: #000 !important;
        border-color: #ccc !important;
        background-color: #fff !important;
    }

    .ant-layout {
        margin: 0 !important;
    }
}